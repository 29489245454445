import { Formik } from 'formik'
import RegistrationForm from './RegistrationForm'
import { useInitialValues } from './hooks/useInitialValues'
import { useValidate } from './hooks/useValidate'
import { useSubmit } from './hooks/useSubmit'
import { observer } from 'mobx-react-lite'

const RegistrationFormIndex = observer(() => {
    const { initialValues } = useInitialValues()
    const { validate } = useValidate()
    const { submit } = useSubmit()

    return (
        <Formik initialValues={initialValues} enableReinitialize validate={validate} onSubmit={submit}>
            <RegistrationForm />
        </Formik>
    )
})

export default RegistrationFormIndex
